import React, { lazy, Suspense } from 'react';

import { AppPlugin, AppRootProps } from '@grafana/data';
import { getWebInstrumentations } from '@grafana/faro-web-sdk';
import { LoadingPlaceholder } from '@grafana/ui';

import { PRIVATE_NETWORKS_PATH } from 'consts';
import { AppJSONData } from 'feature/common/types/App';
import { FARO_ENV, initFaro } from 'feature/common/utils/faro';
import { DataSourceExtensionProps, PDCPluginExtensionDataSourceConfigContext } from 'feature/datasource-config';

// @ts-ignore
import { name, version } from '../package.json';

const LazyApp = lazy(() => import('./feature/common/components/App'));

const LazyDataSourceExtension = React.lazy(() => import('feature/datasource-config/components/DataSourceExtension'));

const App = (props: AppRootProps<AppJSONData>) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyApp {...props} />
  </Suspense>
);
initFaro({
  version,
  paths: [PRIVATE_NETWORKS_PATH],
  getUrl: (environment) => {
    switch (environment) {
      case FARO_ENV.DEV:
        return 'https://faro-collector-prod-us-central-0.grafana.net/collect/1bbded1e7fd5372f574bdb75ffad3930';
      case FARO_ENV.STAGING:
        return 'https://faro-collector-prod-us-central-0.grafana.net/collect/7e621c6b8b3a31c907b0fa165c8d25ca';
      case FARO_ENV.PROD:
      default:
        return 'https://faro-collector-prod-us-central-0.grafana.net/collect/63f0c68e559ee2063855baafd2a0be7c';
    }
  },
  faroConfig: {
    instrumentations: getWebInstrumentations(),
  },
  pluginName: name,
});

export const plugin = new AppPlugin<AppJSONData>().setRootPage(App);

const DataSourceExtension = (props: DataSourceExtensionProps) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyDataSourceExtension {...props} />
  </Suspense>
);

plugin.configureExtensionComponent &&
  plugin.configureExtensionComponent<PDCPluginExtensionDataSourceConfigContext>({
    title: 'PDC Datasource Config',
    description: 'Private data source connection settings',
    extensionPointId: 'grafana/datasources/config',
    component: DataSourceExtension,
  });
